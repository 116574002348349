import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import TestReport from '../views/TestReport.vue'





import NoUnitSelected from '../components/NoUnitSelected.vue' 

import AuthService from '../services/AuthService'
import MyProfileView from '../views/MyProfileView.vue'
import CourseConsumption from '../views/CourseConsumptionView.vue'
import LeaderBoard from '../views/LeaderBoard.vue'
import AllDoughts from '../views/AllDoughts.vue'
import TopicsView from '../components/TopicsView.vue'
import SetUpProfileView from '../views/SetUpProfileView.vue'
import ProductView from '../views/ProductView.vue'
import CourseDescription from "@/components/CourseDescription.vue";
import CurriculumView from "@/components/CurriculumView.vue"
import PracticeTest from "@/components/PracticeTest.vue";
import ReviewsView from "@/components/ReviewsView.vue";
import ReportsAnalysisView from "@/components/ReportsandAnalysisView.vue";
import SubTopicsView from '@/components/SubTopicsDetailsView.vue';
import SubTopicVideoConsumeVue from '@/components/SubTopicVideoConsume';
import PolygonStepperView from '@/components/PolygonStepperView.vue';
import SubTopicPdf from '@/components/SubTopicPdf.vue';
import BrainBooster from '@/components/SubTopicBrainBooster.vue';
import InstructionsPageView from '@/components/InstructionsPageView';
import TestScreenView from '@/views/TestScreenView.vue';
import PostTestReportShowView from '@/views/PostTestReportShowView.vue'
import CourseNotFount from '@/views/CourseNotFound.vue'
import ErrorPageView from '@/components/ErrorPageView.vue'
import DetailedReportView from '@/components/DetailedReportView.vue'
import TimeTableView from '@/components/TimeTableView.vue'
import SubjectReportView from '@/components/SubjectReportView.vue'
import WeekView from '@/components/WeekView.vue'
import DayView from '@/components/DayView.vue'
import LiveClassView from '@/views/LiveClassView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'loginView',
    component: LoginView,
      meta: {
      requiresAuth: false,
    },
  },  

  {
    path: '/post-test-report',
    name: 'PostTestReportShowView',
    component: PostTestReportShowView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/liveClass',
    name: 'LiveClassView',
    component: LiveClassView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/day-view',
    name: 'DayView',
    component: DayView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/week-view',
    name: 'WeekView',
    component: WeekView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subject-report/:subjectName",
    name: "SubjectReportView",
    component: SubjectReportView,
    props: true, // Pass route params as props
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/detailed-report',
    name: 'DetailedReportView',
    component: DetailedReportView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/time-table',
    name: 'TimeTableView',
    component: TimeTableView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/pdfs',
    name: 'PdfViewCompo',
    component: SubTopicPdf,
    meta: {
      requiresAuth: true,
    },
  },


  {
    path: '/test-screen',
    name: 'TestScreenView',
    component: TestScreenView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/polygonStepper',
    name: 'PolygonStepperView',
    component: PolygonStepperView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/errorPageView',
    name: 'ErrorPageView',
    component: ErrorPageView,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/subtopics',
    name: 'SubTopicsView',
    component: SubTopicsView,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/courseDetail',
    name: 'ProductView',
    component: ProductView,
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: '/notfound',
    name: 'notFount',
    component: CourseNotFount,
    meta: {
      requiresAuth: true,
    },
    
  },
  


  
  {
    path: '/reports-analysis',
    name: 'ReportsAnalysisView',
    component: ReportsAnalysisView,
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: '/reviews',
    name: 'ReviewsView',
    component: ReviewsView,
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: '/course-description',
    name: 'CourseDescription',
    component: CourseDescription,
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: '/curriculum',
    name: 'CurriculumView',
    component: CurriculumView,
    meta: {
      requiresAuth: true,
    },
    
  },


  {
    path: '/practice-test',
    name: 'PracticeTest',
    component: PracticeTest,
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: '/',
    name: 'homeView',
    component: HomeView,
    meta: {
    requiresAuth: true,
  }
  },
  {
    path: '/leaderboard',
    name: 'LeaderBoard',
    component: LeaderBoard,
    meta: {
    requiresAuth: true,
  }
  },

  {
    path: '/doughts',
    name: 'AllDoughts',
    component: AllDoughts,
    meta: {
    requiresAuth: true,
  }
  },
  
  {
    path: "/setUpProfileView",
    name: "SetUpProfileView",
    component: SetUpProfileView,
    meta: {
      requiresAuth: true,
    },
  },
  

 

  {
    path: '/profile',
    name: 'MyProfileView',
    component: MyProfileView,
    meta: {
      requiresAuth: true,
    }
  },
  
  {
    path: '/course',
    name: 'CourseConsumptionView',
    meta: {
      requiresAuth: true,
    },
    children: [  
      {
        path: 'topics',
        name: 'Topics',
        component: TopicsView
      },
      {
        path: 'pdf',
        name: 'content',
        component: SubTopicPdf,
      },
      {
        path: 'video',
        name: 'Video',
        component: SubTopicVideoConsumeVue,
      },
      {
        path: 'brainBooster',
        name: 'BrainBooster',
        component: BrainBooster,
      },
      {
        path: 'test',
        name: 'test',
        component: InstructionsPageView,
      },
    
      {
        path: 'subtopics',
        name: 'SubTopics',
        component: SubTopicsView,
      },

      {
        path: '/',
        name: 'NoUnitSelected',
        component: NoUnitSelected
      },
      {
        path: '/report',
        name: 'TestReport',
        component: TestReport,
      
      },
    ],
    component: CourseConsumption
  },  
]

const router = new VueRouter({
  routes,
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : "Eazily-LMS"
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!AuthService.isAuthenticated()) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
