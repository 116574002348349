<template>
    <div class="homePage fixed-top relative d-flex flex-row">
      <div class="d-flex flex-row w-100">
        <!-- Left Navigation -->
        <v-card class="leftSide rounded-0">
          <NavDrawer />
        </v-card>
        <!-- Main Content Section -->
        <div class="detailed-view homeMain">
          <!-- Breadcrumb Navigation -->
          <div class="breadcrumb">
            <v-btn icon @click="goHome">
              <v-icon>mdi-home</v-icon>
            </v-btn>
            <span class="mr-1"> > </span>
            <span v-if="$store.state.UserInfo?.personalCreds?.firstName">
              {{
                this.$store.state.UserInfo?.personalCreds?.firstName +
                  " " +
                  this.$store.state.UserInfo?.personalCreds?.lastName
              }}'s Detailed Report
            </span>
          </div>
          <!-- Close Button -->
          <UserCard style="top: 0px; right: 0px" class="mr-4 mt-4 absolute" />
          <!-- Horizontal Tab Navigation for Subjects -->
          <v-tabs
            v-model="selectedSubjectIndex"
            background-color="transparent"
            slider-color="#D3D3D3"
            @change="onTabChange"
          >
            <v-tab
              v-for="(subject, index) in subjects"
              :key="index"
              :value="subject.indexing"
              :class="subject.color"
            >
              {{ subject.name }}
            </v-tab>
          </v-tabs>
          <!-- Render SubjectReportView Component Dynamically -->
          <div class="right-section">
            <SubjectReportView
              :subject="getSubjectByIndex(selectedSubjectIndex)"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import UserCard from "@/components/UserCard.vue";
  import SubjectReportView from "./SubjectReportView.vue";
  import AuthService from "../services/AuthService";
  import CourseConsumptionController from "@/controllers/CourseConsumptionController";
  
  export default {
    components: {
      UserCard,
      SubjectReportView,
    },
    data() {
      return {
        selectedSubjectIndex: null, // Default to null until subjects load
        subjects: [],
      };
    },
    methods: {
      goHome() {
        this.$router.push("/");
      },
      onTabChange(newTab) {
        console.log(`Switched to tab with indexing: ${newTab}`);
      },
      async getHomeMenu() {
        try {
          const response = await CourseConsumptionController.getEnrolledCourses();
          if (response?.status === 401 || response?.status === 440) {
            AuthService.logout();
          } else if (response?.status === 200) {
            this.subjects = response?.data?.data[0]?.subjects || [];
            if (this.subjects.length > 0) {
              this.selectedSubjectIndex = this.subjects[0].indexing; // Set default selected subject
            }
          } else {
            alert(response.data.error);
          }
        } catch (error) {
          console.error("Error fetching home data:", error);
        }
      },
      getSubjectByIndex(indexing) {
        return this.subjects.find(subject => subject.indexing === indexing) || {};
      },
    },
    mounted() {
      this.getHomeMenu();
    },
  };
  </script>
  
  
  <style scoped>
  /* Overall Page Styling */
  .detailed-view {
    padding: 40px 20px;
    position: relative;
    background-color: #FFFFFF;
    min-height: 100vh;
    width: 100%;
    font-family: 'Roboto', sans-serif;
  }
  /* Breadcrumb Styling */
  .breadcrumb {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 1rem;
    color: #333;
  }
  /* Tab Navigation */
  .v-tabs {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 30px;
  }
  .v-tab {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 10px 20px;
    color: #4A4A4A;
    text-transform: uppercase;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  .right-section {
  /* padding: 20px; */
  background-color: #F9F9F9;
  border-radius: 10px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
  margin-top: 20px;
}
  .v-tab:hover {
    background-color: #E8E8E8;
    border-radius: 5px;
  }
  /* Subject Details Section */
  .subject-details {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  .v-tabs-slider {
  background-color: transparent !important;
  /* Alternatively, set a custom color */
  /* background-color: #A8D8EA !important; */
}
  /* Pastel Colors for Text */
  .pastel-blue {
    color: #A8D8EA;
  }
  .pastel-pink {
    color: #FFC1CC;
  }
  .pastel-green {
    color: #B8E6B9;
  }
  .pastel-purple {
    color: #D7BDE2;
  }
  /* Responsive Adjustments */
  @media screen and (max-width: 768px) {
    .title {
      font-size: 2.5rem;
    }
    .v-tab {
      font-size: 1.2rem;
    }
    .breadcrumb {
      font-size: 1rem;
    }
  }
  </style>