<template>
    <div v-if="subject" class="subject-report-view">
      <div class="mb-15 text-h5 font-weight-bold">{{ subject.name }} Report</div>
  
      <!-- Summary Cards -->
      <div class="summary-cards">
        <div class="card" :style="{ backgroundColor: subject.primaryColor }">
          <p>{{ subject.completedUnits }}/{{ subject.unitsCount }}</p>
          <span class="text-h6">Units Completed</span>
        </div>
        <div class="card" :style="{ backgroundColor: subject.primaryColor }">
          <p>{{ subject.completedChapters }}/{{ subject.chaptersCount }}</p>
          <span class="text-h6">Chapters Completed</span>
        </div>
        <div class="card" :style="{ backgroundColor: subject.primaryColor }">
          <p>{{ subject.completedTopics }}/{{ subject.topicsCount }}</p>
          <span class="text-h6">Topics Completed</span>
        </div>
      </div>
  
      <!-- Charts Section -->
      <div class="charts">
        <div class="chart larger-chart">
          <h3>Units Progress</h3>
          <canvas id="units-chart"></canvas>
        </div>
        <div class="chart larger-chart">
          <h3>Chapters Progress</h3>
          <canvas id="chapters-chart"></canvas>
        </div>
        <div class="chart smaller-chart">
          <h3>Topics Completion</h3>
          <canvas id="topics-donut"></canvas>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { onMounted, watch } from "vue";
  import Chart from "chart.js/auto";
  
  export default {
    props: {
      subject: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const charts = {};
  
      const renderCharts = () => {
        const primaryColor = props.subject?.primaryColor || "#000"; // Fallback color
  
        // Destroy existing charts before creating new ones
        destroyChart("units-chart");
        destroyChart("chapters-chart");
        destroyChart("topics-donut");
  
        // Render Charts with safety checks
        renderBarChart(props.subject?.units || [], primaryColor);
        renderLineChart(props.subject?.units || [], primaryColor);
        renderDonutChart(
          props.subject?.completedTopics || 0,
          props.subject?.topicsCount || 1,
          primaryColor
        );
      };
  
      const destroyChart = (chartId) => {
        if (charts[chartId]) {
          charts[chartId].destroy();
          delete charts[chartId]; // Remove reference to destroyed chart
        }
      };
  
      const renderBarChart = (units, color) => {
        if (!Array.isArray(units) || units.length === 0) {
          console.warn("Units data is not available or is empty.");
          return;
        }
  
        const ctx = document.getElementById("units-chart")?.getContext("2d");
        if (!ctx) {
          console.error("Canvas element for units-chart not found.");
          return;
        }
  
        charts["units-chart"] = new Chart(ctx, {
          type: "bar",
          data: {
            labels: units.map((unit) =>
              unit.unitName?.length > 12 ? `${unit.unitName.slice(0, 12)}...` : unit.unitName || "Unnamed"
            ),
            datasets: [
              {
                label: "Progress",
                data: units.map((unit) =>
                  unit.totalChaptersCount
                    ? (unit.completedChaptersCount / unit.totalChaptersCount) * 100
                    : 0
                ),
                backgroundColor: color,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
          },
        });
      };
  
      const renderLineChart = (units, color) => {
        if (!Array.isArray(units) || units.length === 0) {
          console.warn("Units data is not available or is empty.");
          return;
        }
  
        const chapters = units.flatMap((unit) => unit.chapters || []); // Safely flatten chapters
        if (chapters.length === 0) {
          console.warn("No chapters found in units.");
          return;
        }
  
        const ctx = document.getElementById("chapters-chart")?.getContext("2d");
        if (!ctx) {
          console.error("Canvas element for chapters-chart not found.");
          return;
        }
  
        charts["chapters-chart"] = new Chart(ctx, {
          type: "line",
          data: {
            labels: chapters.map((chapter) =>
              chapter.chapterName?.length > 12 ? `${chapter.chapterName.slice(0, 12)}...` : chapter.chapterName || "Unnamed"
            ),
            datasets: [
              {
                label: "Chapter Progress (%)",
                data: chapters.map((chapter) =>
                  chapter.totalTopicsCount
                    ? (chapter.completedTopicsCount / chapter.totalTopicsCount) * 100
                    : 0
                ),
                borderColor: color,
                fill: false,
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
          },
        });
      };
  
      const renderDonutChart = (completed, total, color) => {
        const ctx = document.getElementById("topics-donut")?.getContext("2d");
        if (!ctx) {
          console.error("Canvas element for topics-donut not found.");
          return;
        }
  
        charts["topics-donut"] = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: ["Completed", "Remaining"],
            datasets: [
              {
                data: [completed, Math.max(total - completed, 0)],
                backgroundColor: [color, "#e0e0e0"],
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
          },
        });
      };
  
      onMounted(() => {
        console.log("Subject data on mount:", props.subject);
        renderCharts();
      });
  
      watch(
        () => props.subject,
        () => {
          console.log("Subject data updated:", props.subject);
          renderCharts();
        },
        { deep: true }
      );
  
      return {};
    },
  };
  </script>
  
  
  
  
  <style scoped>
  .subject-report-view {
    padding: 20px;
    background-color: #f5f5f5; /* Light grey background */
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 194px);
    /* width: calc(100% - 17.438rem); */
    color: #333;
  }
  
  .professor-info {
    margin-bottom: 20px;
  }
  
  .summary-cards {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .card {
    flex: 1;
    padding: 20px;
    font-size: 20px;
    background-color: #fff; 
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .card span {
    display: block;
    margin-top: 10px;
    
  }
  
  .charts {
    display: flex;
    gap: 10px;
  }
  
  .chart {
    margin-top: 15px;
    flex: 1;
    background-color: #fff; 
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Adjust chart sizes */
  .larger-chart canvas {
    height: 200px !important; 
  }
  
  .smaller-chart canvas {
    height: 200px !important; 
  }
  </style>
