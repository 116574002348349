<template>
  <div class="day-view">
    <!-- Header displaying selected date -->
    <div class="day-header">
      <h3>{{ selectedDateFormatted }}</h3>
    </div>

    <!-- Scrollable container for time slots -->
    <div class="time-slots-container">
      <div class="time-slots">
        <!-- Loop over hours and create each row with horizontal lines -->
        <div v-for="hour in hours" :key="hour" class="time-slot">
          <div class="hour">{{ hour }}</div>
          <div class="event-container" @click="openEventPopup(hour)">
            <!-- Events for this hour -->
            <div
              v-for="event in getEventsForHour(hour)"
              :key="event.id"
              class="event"
            >
              {{ event.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Event Popup -->
    <div v-if="showEventPopup" class="popup-overlay">
      <div class="popup-content">
        <h3>Add Event</h3>
        <p>{{ selectedDateFormatted }} at {{ selectedSlot.hour }}</p>
        <input v-model="eventTitle" type="text" placeholder="Event Title" />
        <textarea
          v-model="eventDescription"
          placeholder="Event Description"
        ></textarea>
        <div class="popup-buttons">
          <button @click="saveEvent">Save</button>
          <button @click="closeEventPopup">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      // Selected date to display (you can pass this dynamically from the parent component)
      selectedDate: new Date(), // Default to today's date

      // Time slots for the day
      hours: [
        "1 AM",
        "2 AM",
        "3 AM",
        "4 AM",
        "5 AM",
        "6 AM",
        "7 AM",
        "8 AM",
        "9 AM",
        "10 AM",
        "11 AM",
        "12 PM",
        "1 PM",
        "2 PM",
        "3 PM",
        "4 PM",
        "5 PM",
        "6 PM",
        "7 PM",
        "8 PM",
        "9 PM",
        "10 PM",
        "11 PM",
      ],

      // Event management
      showEventPopup: false,
      selectedSlot: { hour: "" },
      eventTitle: "",
      eventDescription: "",
      events: [], // Example events data, should be fetched from your store or API
    };
  },
  computed: {
    // Format the selected date for display
    selectedDateFormatted() {
      return this.selectedDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  methods: {
    // Get events for a particular hour
    getEventsForHour(hour) {
      console.log(
        `Events for ${hour}:`,
        this.events.filter((event) => event.hour === hour)
      ); // Debugging line
      return this.events.filter((event) => event.hour === hour);
    },

    // Open event popup
    openEventPopup(hour) {
      console.log("vh", hour);
      this.selectedSlot = { hour };
      this.showEventPopup = true;
      this.eventTitle = "";
      this.eventDescription = "";
    },

    // Close event popup
    closeEventPopup() {
      this.showEventPopup = false;
    },

    // Save new event (you would probably want to save this to a backend or Vuex store)
    saveEvent() {
      if (this.eventTitle.trim()) {
        this.events.push({
          id: Date.now(),
          title: this.eventTitle,
          description: this.eventDescription,
          hour: this.selectedSlot.hour,
        });
      }
      this.closeEventPopup();
    },
  },
};
</script>
  
  <style scoped>
/* Day View Layout */
.day-view {
  padding: 0px;
  background-color: #f9f9f9;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.day-header {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.time-slots-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-y: auto; /* Allows scrolling vertically */
}

.time-slots {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll; /* Makes the time slots scrollable */
}

.time-slot {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 0px 0;
}

.hour {
  width: 80px;
  text-align: center;
  font-weight: bold;
  color: #555;
}

.event-container {
  position: relative;
  flex-grow: 1;
  padding-left: 20px;
  background-color: lightgray; /* Add this for debugging */
  cursor: pointer;
}

.event {
  background-color: #4caf50;
  color: white;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
}

.popup-buttons button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}

.popup-buttons button:hover {
  background-color: #ddd;
}
</style>
  