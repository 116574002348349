<template>
    <div ref="fullscreenElement" class="live-class-container">
      <div fluid style="height: 100vh !important" class="conClass">
        <div class="parentCard">
          <v-layout row wrap align-content-space-between class="layoutClass">
            <v-card
              :height="calculateHeight()"
              :width="calculateWidth()"
              v-for="user in remoteStreams"
              :key="user.uid"
              class="ma-2 d-flex justify-center"
            >
              <video :id="'player-' + user.uid" class="remoteVideo"></video>
            </v-card>
            <v-btn fab bottom fixed right elevation="0" color="transparent">
              <video class="localVideo" id="local-player"></video>
            </v-btn>
          </v-layout>
        </div>
  
        <v-bottom-navigation fixed grow color="indigo">
          <v-btn @click="toggleMute">
            <span>Mute</span>
          </v-btn>
          <v-btn @click="endCall" color="red">
            <span>End Call</span>
          </v-btn>
          <v-btn @click="shareScreen" v-if="!isScreenShared">
            <span>Share Screen</span>
          </v-btn>
          <v-btn v-else @click="stopScreenShare">
            <span>Stop Sharing</span>
          </v-btn>
        </v-bottom-navigation>
      </div>
    </div>
  </template>
    
    <script>
  import AgoraRTC from "agora-rtc-sdk-ng";
  
  export default {
    name: "LiveClassView",
    data() {
      return {
        appId: "37fc414f87e54b7586f378bed33c74f0", // Your Agora App ID
        channelName: "",
        client: null,
        localStream: {},
        remoteStreams: [],
        isScreenShared: false,
      };
    },
    async mounted() {
      try {
        if (!this.$route.query.id) {
          this.$router.replace("/");
        }
        this.channelName = this.$route.query.id;
  
        // Fullscreen logic
        this.enterFullScreen();
  
        this.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        this.client.on("user-published", this.handleUserPublished);
        this.client.on("user-unpublished", this.handleUserUnpublished);
  
         await this.client.join(this.appId, this.channelName, null);
        const [audioTrack, videoTrack] =
          await AgoraRTC.createMicrophoneAndCameraTracks();
        this.localStream = { audioTrack, videoTrack };
  
        await this.client.publish(Object.values(this.localStream));
        videoTrack.play("local-player");
      } catch (error) {
        console.error("Error initializing Agora:", error);
      }
    },
    methods: {
      enterFullScreen() {
        const elem = this.$refs.fullscreenElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(); // Safari support
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen(); // IE/Edge support
        }
      },
      async handleUserPublished(user, mediaType) {
        try {
          await this.client.subscribe(user, mediaType);
  
          if (mediaType === "video") {
            this.remoteStreams.push(user);
            this.$nextTick(() => user.videoTrack.play("player-" + user.uid));
          } else if (mediaType === "audio") {
            user.audioTrack.play();
          }
        } catch (error) {
          console.error("Error subscribing to user:", error);
        }
      },
      handleUserUnpublished(user) {
        const index = this.remoteStreams.findIndex((u) => u.uid === user.uid);
        if (index !== -1) this.remoteStreams.splice(index, 1);
      },
      toggleMute() {
        if (this.localStream.audioTrack.isEnabled) {
          this.localStream.audioTrack.setEnabled(false);
        } else {
          this.localStream.audioTrack.setEnabled(true);
        }
      },
      async endCall() {
        try {
          // Stop and close local video and audio tracks
          if (this.localStream) {
            if (this.localStream.videoTrack) {
              this.localStream.videoTrack.stop();
              this.localStream.videoTrack.close();
            }
            if (this.localStream.audioTrack) {
              this.localStream.audioTrack.stop();
              this.localStream.audioTrack.close();
            }
          }
  
          // Leave the channel
          await this.client.leave();
  
          // Reset local state
          this.remoteStreams = [];
          this.localStream = null;
          this.client = null;
  
          // Redirect to the homepage
          this.$router.replace("/");
        } catch (error) {
          console.error("Error leaving the channel:", error);
        }
      },
      async shareScreen() {
        try {
          const screenTrack = await AgoraRTC.createScreenVideoTrack();
          await this.client.unpublish([this.localStream.videoTrack]);
          await this.client.publish([screenTrack]);
          this.localStream.videoTrack = screenTrack;
          screenTrack.play("local-player");
          this.isScreenShared = true;
        } catch (error) {
          console.error("Error sharing screen:", error);
        }
      },
      async stopScreenShare() {
        try {
          const cameraTrack = await AgoraRTC.createCameraVideoTrack();
          await this.client.unpublish([this.localStream.videoTrack]);
          await this.client.publish([cameraTrack]);
          this.localStream.videoTrack = cameraTrack;
          cameraTrack.play("local-player");
          this.isScreenShared = false;
        } catch (error) {
          console.error("Error stopping screen share:", error);
        }
      },
    },
  };
  </script>
    
    <style scoped>
  .live-class-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    overflow: hidden;
  }
  </style>
    