<template>
  <div class="homePage fixed-top relative d-flex flex-row">
    <div class="d-flex flex-row w-100">
      <!-- leftnav card -->
      <v-card class="leftSide rounded-0">
        <NavDrawer />
      </v-card>

      <div class="timetable">
        <!-- Header -->
        <header class="header">
          <div class="greeting">
            <span v-if="$store.state.UserInfo?.personalCreds?.firstName">{{
              this.$store.state.UserInfo?.personalCreds?.firstName +
              " " +
              this.$store.state.UserInfo?.personalCreds?.lastName
            }}</span>
          </div>
          <p class="subtext">You are doing great. Keep Studying!</p>

          <!-- Close Button (Cross) -->
          <!-- <button class="close-btn" @click="closeTimetable">×</button> -->
        </header>

        <!-- View Navigation -->
        <div class="view-navigation">
          <button :class="{ active: view === 'month' }" @click="view = 'month'">
            Month
          </button>
          <button :class="{ active: view === 'week' }" @click="view = 'week'">
            Week
          </button>
          <button :class="{ active: view === 'day' }" @click="view = 'day'">
            Day
          </button>
        </div>

        <!-- Calendar -->
        <div class="calendar" v-if="view === 'month'">
          <div class="calendar-header">
            <button class="nav-btn" @click="prevMonth">❮</button>
            <h2 class="month-title">
              {{ monthNames[currentMonth] }} {{ currentYear }}
            </h2>
            <button class="nav-btn" @click="nextMonth">❯</button>
          </div>

          <div class="calendar-grid">
            <div class="day-name" v-for="day in days" :key="day">{{ day }}</div>
            <div
              class="calendar-cell"
              v-for="day in calendarDays"
              :key="day.date"
            >
              <div class="date">{{ day.date }}</div>
              <div class="events">
                <div
                  v-for="(event, index) in day.events"
                  :key="event.id || index"
                  class="event"
                  :title="event.title || `Event-${index + 1}`"
                  @click="viewEvent(event)"
                >
                  {{ event.title || `Event-${index + 1}` }}
                </div>
              </div>
              <button
                v-if="day.date"
                class="add-btn"
                @click="openAddEvent(day.date)"
              >
                +
              </button>
            </div>
          </div>
        </div>

        <WeekView v-if="view === 'week'" />
        <DayView v-if="view === 'day'" />

        <!-- Add Event Modal -->
        <div v-if="showModal" class="modal-overlay">
          <div class="modal">
            <h3>Add Event</h3>
            <!-- <label for="eventTitle">Event Title:</label> -->
            <input
              id="eventTitle"
              v-model="newEvent.title"
              type="text"
              placeholder="Enter event title"
              class="modal-input"
            />
            <div class="selected-date">Selected Date: {{ newEvent.date }}</div>
            <div class="modal-actions">
              <button class="save-btn" @click="addEvent">Save</button>
              <button class="cancel-btn" @click="closeModal">Cancel</button>
            </div>
          </div>
        </div>

        <!-- View Event Modal -->
        <div v-if="showEventModal" class="modal-overlay">
          <div class="modal">
            <!-- Close Icon -->
            <button class="close-icon" @click="closeEventModal">✖</button>

            <!-- Event Description -->
            <p class="description">{{ selectedEvent.description }}</p>

            <!-- Event Details -->
            <div class="event-details">
              <p>
                <strong>Date:</strong> {{ selectedEvent.startDate }} -
                {{ selectedEvent.endDate }}
              </p>
              <p>
                <strong>Time:</strong> {{ selectedEvent.startTime }} -
                {{ selectedEvent.endTime }}
              </p>
            </div>

            <p v-if="selectedEvent.teachers && selectedEvent.teachers.length">
              <strong>Teacher:</strong> {{ selectedEvent.teachers.join(", ") }}
            </p>

            <!-- Join the Class Button -->
            <button class="join-btn" @click="joinClass(selectedEvent)">
              Join the Class
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { db } from "../firebase";
import { doc, collection, getDocs, query, where } from "firebase/firestore";
import WeekView from "./WeekView.vue";
import DayView from "./DayView.vue";

export default {
  components: {
    WeekView,
    DayView,
  },
  data() {
    return {
      view: "month",
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      events: [], // Will hold dynamically fetched events
      showModal: false,
      newEvent: { title: "", date: "" },
      showEventModal: false,
    };
  },
  computed: {
    calendarDays() {
      const firstDay = new Date(
        this.currentYear,
        this.currentMonth,
        1
      ).getDay();
      const daysInMonth = new Date(
        this.currentYear,
        this.currentMonth + 1,
        0
      ).getDate();

      const days = [];
      for (let i = 0; i < firstDay; i++) {
        days.push({ date: "", events: [] });
      }
      for (let i = 1; i <= daysInMonth; i++) {
        const dateString = `${this.currentYear}-${(this.currentMonth + 1)
          .toString()
          .padStart(2, "0")}-${i.toString().padStart(2, "0")}`;
        days.push({
          date: i,
          events: this.events.filter((event) => event.startDate === dateString),
        });
      }
      return days;
    },
  },
  methods: {
    async fetchEvents() {
      const userId = this.$store.state.UserInfo?.userId; // Replace with your actual user ID logic
      const eventsRef = collection(doc(db, "users", userId), "events");

      try {
        const querySnapshot = await getDocs(eventsRef);
        const events = [];
        querySnapshot.forEach((doc) => {
          events.push({ id: doc.id, ...doc.data() });
        });
        this.events = events;
        console.log("events:", this.events);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    },

    async joinClass(selectedEvent) {
      try {
        const userId = this.$store.state.UserInfo?.userId; // Fetch user ID
        const eventsRef = collection(doc(db, "users", userId), "events");

        // Query the event by the "id" field
        const eventQuery = query(
          eventsRef,
          where("id", "==", selectedEvent.id)
        );
        const querySnapshot = await getDocs(eventQuery);

        if (querySnapshot.empty) {
          alert("Event not found. Please contact support.");
          return;
        }

        const eventDoc = querySnapshot.docs[0];
        const eventData = eventDoc.data();

        // Check if the event is live
        if (eventData.isLive) {
          alert("You can join the class now!");
          // Redirect to the LiveClassView page with the channel name
          this.$router.push({
            path: "/liveClass",
            query: { id: selectedEvent.id }, // Pass the channel ID
          });
        } else {
          alert("Wait for the host to begin the class.");
        }
      } catch (error) {
        console.error("Error joining the class:", error);
        alert("An error occurred while trying to join the class.");
      }
    },

    prevMonth() {
      this.currentMonth = this.currentMonth === 0 ? 11 : this.currentMonth - 1;
      if (this.currentMonth === 11) this.currentYear -= 1;
    },
    nextMonth() {
      this.currentMonth = this.currentMonth === 11 ? 0 : this.currentMonth + 1;
      if (this.currentMonth === 0) this.currentYear += 1;
    },
    openAddEvent(date) {
      this.newEvent.date = `${this.currentYear}-${(this.currentMonth + 1)
        .toString()
        .padStart(2, "0")}-${date.toString().padStart(2, "0")}`;
      this.showModal = true;
    },
    addEvent() {
      if (this.newEvent.title.trim()) {
        this.events.push({
          id: Date.now(),
          title: this.newEvent.title,
          startDate: this.newEvent.date,
        });
        this.newEvent = { title: "", date: "" };
        this.showModal = false;
      } else {
        alert("Please enter a valid event title.");
      }
    },
    viewEvent(event) {
      this.selectedEvent = event;
      this.showEventModal = true;
    },
    closeEventModal() {
      this.selectedEvent = null;
      this.showEventModal = false;
    },
    closeModal() {
      this.newEvent = { title: "", date: "" };
      this.showModal = false;
    },
    closeTimetable() {
      this.$router.push("/");
    },
  },
  async mounted() {
    await this.fetchEvents(); // Fetch events on component mount
  },
};
</script>

  
  <style scoped>
/* General Styling */
.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 30px;
  color: #ff4d4d;
  cursor: pointer;
  transition: 0.3s;
}

.close-btn:hover {
  color: #e74c3c;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.description {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.event-details p {
  font-size: 14px;
  margin: 5px 0;
}

.join-btn {
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 15px;
  width: 100%;
}

.join-btn:hover {
  background-color: #45a049;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.timetable {
  background: #f8f9fa;
  padding: 20px;
  width: 100%;
  height: 100vh;

  /* border-radius: 10px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}
.header {
  text-align: center;
  margin-bottom: 20px;
}
.greeting {
  font-size: 24px;
  color: #4a4a4a;
}
.subtext {
  color: #6c757d;
}
.view-navigation button {
  background: #ffdfd4;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s;
}
.view-navigation button.active {
  background: #ffb3b3;
  color: white;
}
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  height: 570px; /* Set a maximum height to define the scrollable area */
  overflow-y: auto;
}
.calendar-cell {
  position: relative;
  background: #f3e9ff;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 95px; /* Set a fixed height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
/* Event Styling */
.events {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.event {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Display ... when text overflows */
  font-size: 14px;
  padding: 3px;
  margin-bottom: 5px;
}

/* Add a tooltip to show the full event title on hover */
.event:hover {
  cursor: pointer;
  text-decoration: underline;
}

.event::after {
  content: attr(title);
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.event:hover::after {
  visibility: visible;
  white-space: normal;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 5px;
  z-index: 10;
}
.add-btn {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: #ffb3b3;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 16px;
  cursor: pointer;
}

/* Modal */
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Slightly dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

/* Modal Box */
.modal {
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 400px; /* Fixed width for better alignment */
  transition: transform 0.3s ease-out;
  transform: scale(1.05);
}

/* Heading */
.modal h3 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

/* Input Field */
.modal-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
}

.modal-input:focus {
  border-color: #00b894;
  outline: none;
}

/* Date Text */
.selected-date {
  font-size: 14px;
  color: #777;
  margin-bottom: 25px;
}

/* Action Buttons */
.modal-actions button {
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  width: 45%;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

/* Save Button */
.save-btn {
  background-color: #00b894;
  color: white;
  border: none;
}

.save-btn:hover {
  background-color: #019e77;
}

/* Cancel Button */
.cancel-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
}

.cancel-btn:hover {
  background-color: #c0392b;
}

/* Fade-In Effect for Modal */
.modal-overlay {
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.calendar-header {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center everything horizontally */
  gap: 20px; /* Add spacing between elements */
  margin-bottom: 20px; /* Space below the header */
}

.nav-btn {
  background: #ffb3b3; /* Pastel button color */
  border: none;
  color: white;
  font-size: 20px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease;
}

.nav-btn:hover {
  background: #ff9f9f; /* Slightly darker shade on hover */
}

.month-title {
  font-size: 24px;
  font-weight: bold;
  color: #4a4a4a;
}
</style>
  