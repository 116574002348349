<template>
  <div>
    <div class="calendar-1">
      <!-- Weekday Headers with Dates -->
      <div class="header-1">
        <div v-for="(day, index) in days" :key="day" class="header-day">
          <div class="weekday">{{ day }}</div>
          <div class="date-1">{{ getFormattedDate(index) }}</div>
        </div>
      </div>

      <!-- Time Slots with Vertical Scroll -->
      <div class="slots">
        <div v-for="hour in hours" :key="hour" class="row-1">
          <div class="hour">{{ hour }}</div>
          <div
            v-for="day in days"
            :key="day + hour"
            class="slot-1"
            @click="openEventPopup(day, hour)"
          >
            <!-- Show Events -->
            <div
              v-for="event in getEventsForSlot(day, hour)"
              :key="event.id"
              class="event"
            >
              {{ event.title }}
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <!-- Add Event Popup -->
    <div v-if="showEventPopup" class="popup-overlay">
  <div class="popup-content">
    <h3>Add Event</h3>
    <p class="popup-slot-details">
      {{ selectedSlot.day }}, {{ getFormattedDateForPopup(selectedSlot.day) }} at {{ selectedSlot.hour }}
    </p>
    <input
      v-model="eventTitle"
      type="text"
      class="popup-input"
      placeholder="Event Title"
    />
    <textarea
      v-model="eventDescription"
      class="popup-textarea"
      placeholder="Event Description"
    ></textarea>
    <div class="popup-buttons">
      <button class="btn btn-save" @click="saveEvent">Save</button>
      <button class="btn btn-cancel" @click="closeEventPopup">Cancel</button>
    </div>
  </div>
</div>

  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      // Calendar setup
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      hours: [
        "12 AM",
        "1 AM",
        "2 AM",
        "3 AM",
        "4 AM",
        "5 AM",
        "6 AM",
        "7 AM",
        "8 AM",
        "9 AM",
        "10 AM",
        "11 AM",
        "12 PM",
        "1 PM",
        "2 PM",
        "3 PM",
        "4 PM",
        "5 PM",
        "6 PM",
        "7 PM",
        "8 PM",
        "9 PM",
        "10 PM",
        "11 PM",
      ],

      // Popup state
      showEventPopup: false,
      selectedSlot: { day: "", hour: "" },

      // Event form
      eventTitle: "",
      eventDescription: "",

      // Events data
      events: [],
    };
  },
  methods: {
    openEventPopup(day, hour) {
      this.selectedSlot = { day, hour };
      this.eventTitle = "";
      this.eventDescription = "";
      this.showEventPopup = true;
    },
    closeEventPopup() {
      this.showEventPopup = false;
    },
    saveEvent() {
      if (this.eventTitle.trim()) {
        this.events.push({
          id: Date.now(),
          day: this.selectedSlot.day,
          hour: this.selectedSlot.hour,
          title: this.eventTitle,
          description: this.eventDescription,
        });
      }
      this.closeEventPopup();
    },
    getEventsForSlot(day, hour) {
      return this.events.filter(
        (event) => event.day === day && event.hour === hour
      );
    },
    getFormattedDateForPopup(day) {
    const today = new Date();
    const currentDayIndex = today.getDay();
    const diff = this.days.indexOf(day) - currentDayIndex;
    const eventDate = new Date(today.setDate(today.getDate() + diff));
    return eventDate.toLocaleDateString(); // Format as "MM/DD/YYYY" or adjust as needed
  },
    getFormattedDate(dayIndex) {
      const today = new Date();
      const firstDayOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay() + dayIndex)
      );
      return `${firstDayOfWeek.getDate()} ${firstDayOfWeek.toLocaleString(
        "default",
        { month: "short" }
      )}`;
    },
  },
};
</script>
  
  <style>
/* Calendar Layout */
.calendar-1 {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0px;
  /* height: calc(100vh - 50px);
  overflow-y: auto;  */
}

.header-1 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: #f4f4f4;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.header-day {
  padding: 10px;
  border: 1px solid #ddd;
}

.weekday {
  font-weight: bold;
}

.date-1 {
  font-size: 12px;
  color: #666;
}

.slots {
  height: 600px;
  overflow-y: auto;
  border-top: 1px solid #ccc;
}

.row-1 {
  display: grid;
  grid-template-columns: 50px repeat(7, 1fr);
}

.hour {
  text-align: center;
  font-size: 10px;
  border: 1px solid #ddd;
  background: #f9f9f9;
  line-height: 40px;
}

.slot-1 {
  height: 40px;
  border: 1px solid #ddd;
  position: relative;
  cursor: pointer;
}

.slot-1:hover {
  background-color: #f0f0f0;
}

.event {
  background: #4caf50;
  color: white;
  font-size: 12px;
  padding: 2px 5px;
  margin: 2px;
  border-radius: 3px;
}

/* Popup Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Popup Content */
.popup-content {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.3s ease-out;
}

/* Header */
.popup-content h3 {
  color: #333;
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.5em;
  font-weight: bold;
}

/* Slot Details */
.popup-slot-details {
  color: #555;
  font-size: 1em;
  text-align: center;
  margin-bottom: 15px;
}

/* Input Styles */
.popup-input,
.popup-textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
}

.popup-input:focus,
.popup-textarea:focus {
  border-color: #4caf50;
  box-shadow: 0px 0px 5px rgba(76, 175, 80, 0.5);
}

/* Textarea */
.popup-textarea {
  height: 80px;
  resize: none;
}

/* Buttons */
.popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;
}

.btn {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-save {
  background-color: #4caf50;
  color: white;
  transition: background-color 0.3s ease;
}

.btn-save:hover {
  background-color: #45a049;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
  transition: background-color 0.3s ease;
}

.btn-cancel:hover {
  background-color: #e53935;
}

/* Popup Animation */
@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

</style>
  